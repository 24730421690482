import {VuexModule, Module, Action, Mutation, getModule} from 'vuex-module-decorators';
import request from '@/services/common/request.service';
import { getAppType, setAccount } from '@/services/common/cookies.service';
import router, { resetRouter } from '@/router';
import { MenuStore } from './menu.store';
import i18n, {getLocale} from '@/lang';
import store from '@/store/index';
import BrowserService from '@/services/common/browser.service';
import RouteService from '@/services/common/route.service';
import {getAccount, setLanguage, getLanguage} from '@/services/common/cookies.service';
import UtilService from '@/services/common/util.service';
import MomentService from '@/services/common/moment.service';

export interface IUserState {
    _id: string;
    countryCode: string;
    sysType: string;
    sysName: string;
    accName: string;
    keys: {[key: string]: string}; // Pass any keys to client side e.g. adobe client key
    sysLang: string[];
    roles: string[];
    stores: {}; // Aws or Alibaba
    account: any;
    accounts: any[];
    verificationType: string;
    appType: string;
    displayName: string;
    picture: string;
    language: string;
    timeZone: string;
    menu: string[];
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
    // tslint:disable-next-line:variable-name
    _id = '';
    countryCode = 'my'; // 2 letter phone code
    sysType = ''; // school, hotel, business
    sysName = ''; // school2me, hotel2us, masjid2me
    accName = ''; // Account name
    keys = {};
    sysLang: string[] = [];
    roles: string[] = []; // Overall role
    stores = {};
    account: any = null;
    lastAccount: any = null;
    accounts: any[] = [];
    verificationType = ''; // mobile / email
    appType = ''; // Staff, User
    displayName = '';
    picture = '';
    language = 'en'; // getLocale();
    timeZone = '';
    menu: string[] = [];

    @Mutation
    private SET_USER(user: IUserState) {
        this._id = user._id;
        this.countryCode = user.countryCode;
        this.sysType = user.sysType;
        this.sysName = user.sysName;
        this.accName = user.accName;
        this.sysLang = user.sysLang;
        this.keys = user.keys;
        this.roles = user.roles;
        this.stores = user.stores;
        this.account = user.account;
        this.lastAccount = user.account;
        this.accounts = user.accounts;
        this.verificationType = user.verificationType;
        this.appType = user.appType;
        this.displayName = user.displayName;
        this.picture = user.picture;
        this.language = user.language;
        this.timeZone = user.timeZone;
        this.menu = user.menu;
    }

    @Mutation
    private SET_NAME(name: string) {
        this.displayName = name;
    }

    @Mutation
    private SET_PICTURE(picture: any) {
        this.picture = picture;
    }

    @Mutation
    private SET_LANGUAGE(language: string) {
        this.language = language;
    }

    @Mutation
    private SET_LAST_ACCOUNT(account: string) {
        this.lastAccount = account;
    }

    @Mutation
    private SET_ACCOUNT(account: any) {
        this.account = account;
    }

    @Mutation
    private SET_COUNTRY_CODE(countryCode: string) {
        this.countryCode = countryCode;
    }

    @Action({rawError: true})
    async SetName(name: string) {
        this.SET_NAME(name);
    }

    @Action({rawError: true})
    async SetPicture(picture: any) {
        this.SET_PICTURE(picture);
    }

    @Action({rawError: true})
    async SetLanguage(language: string) {
        if (this._id) {
            if (language !== getLanguage()) {
                await request({url: '/api/users/language', method: 'post', params: {language}});
            }
            setLanguage(language);
        }
        this.SET_LANGUAGE(language);
        i18n.locale = language;
        MomentService.initLocale(language);
    }

    @Action({rawError: true})
    async SetLastAccount(account: string) {
        this.SET_LAST_ACCOUNT(account);
    }

    @Action({rawError: true})
    async SetAccount(account: any) {
        this.SET_ACCOUNT(account);
        setAccount(UtilService.getId(account));
    }

    @Action({rawError: true})
    async SetCountryCode(countryCode: string) {
        this.SET_COUNTRY_CODE(countryCode);
    }

    @Action({rawError: true})
    private async initData(data: IUserState) {
        if (data) {
            this.SET_USER(data);
            this.SetLanguage(data.language || getLocale());
            MomentService.initTimeZone(data.timeZone);
            this.SetAccount(data.account);
        }
        resetRouter();
        await MenuStore.GenerateRoutes(this.menu);
        RouteService.addRouterRoutes(router, MenuStore.menuRoutes);
    }

    @Action({rawError: true})
    async Login(userInfo: {username: string, password: string, account: string, system: string, language: string, accName: string}) {
        userInfo.username = userInfo.username.trim();
        const account = getAccount();
        if (account) { userInfo.account = account; }
        const data: any = await request({url: `/api/auth/signin`, method: 'post', data: userInfo});
        if (data) {
            await this.initData(data);
            if (data && data.redirect) {
                window.location.href = data.redirect;
            }
        }
    }

    @Action({rawError: true})
    async Logout() {
        const data: any = await request({url: `/api/auth/signout`, method: 'post', data: {language: getLanguage()}});
        if (data) { await this.initData(data); }
    }

    @Action({rawError: true})
    async getUser(system: string) { // Reload page need to get user info from server
        // console.log('Get', system);
        try {
            const params: any = {system: system || window.location.pathname};
            // Use hostname to get account for site
            if (system === '/site') {
                const pathnames = window.location.pathname.split('/');
                params.hostname = (window.location.pathname.indexOf('/site/edit') === 0 || window.location.pathname.indexOf('/site/help') === 0) ? (pathnames.length > 3 ? pathnames[3] : '') : (pathnames.length > 2 ? pathnames[2] : '');
            } else {
                const acc = RouteService.getQueryParam('account');
                if (acc || window.location.href.indexOf('account') >= 0) {  // Hub may pass account = all.
                    params.account = acc;
                } else {
                    params.account = getAccount() || '';
                }
            }

            const at = RouteService.getQueryParam('appType');
            if (at) {
                params.appType = at;
            } else {
                const appType = getAppType() || '';
                params.appType = appType;
            }
            const language = getLanguage() || RouteService.getQueryParam('language');
            if (language) {
                params.language = language;
            }
            const data: any = await request({url: `/api/auth/get_user`, method: 'get', params});
            if (data) {
                if (data.sysType) {
                    BrowserService.changeFavicon('/static/images/' + data.sysType + '_favicon.png');
                }
                await this.initData(data);
                document.title = UserStore.sysName;
            }
        } catch (err) {
            resetRouter();
        }
    }

}

export const UserStore = getModule(User);
