import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Router from '@/router';
import Qs from 'qs';
import ErrorService from '@/services/common/error.service';
import { getWebVersion, setWebVersion } from './cookies.service';
import RouteService from './route.service';
import { UserStore } from '@/store/user.store';

const returnData = (response: any) => {
    return response && response.headers['content-type'] && response.headers['content-type'].indexOf('application/json') >= 0 ? response.data : response;
};

const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout: 60000,
    paramsSerializer: (params) => {
        return Qs.stringify(params, {arrayFormat: 'indices'});
    },
    // withCredentials: true // send cookies when cross-domain requests
});

// Request interceptors
service.interceptors.request.use(
    (config) => {
        NProgress.start();
        // Add X-Access-Token header to every request, you can add other custom headers here
        // if (UserModule.token) {
        //   config.headers['X-Access-Token'] = UserModule.token;
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const handleErrorResponse = (response: any) => {
    let promise = Promise.resolve();
    if (response.status === 401 && Router && Router.currentRoute && Router.currentRoute.name !== 'login') { // Session expired!
        RouteService.routeLoginRedirect(Router.currentRoute.fullPath, UserStore.appType, UserStore.account);
    } else {
        if (response && response.data) {
            const msg = response.data.message ? response.data.message : (response.statusText || 'Error!');
            const i18n = response.data.i18n;
            const fieldErrors = response.data.fieldErrors || response.data.errors;
            promise = Promise.reject(new ErrorService(response.status, msg, i18n, fieldErrors && Object.keys(fieldErrors).length > 0 ? fieldErrors : null));
        }
    }
    return promise;
};

const checkReload = (response: any) => {
    if (response.config.method === 'get') {
        // Dont reload until server provides info
        if (UserStore.sysType) {
            const webVersion = response && response.headers ? response.headers[`webversion`] : '';
            const cookieVersion = getWebVersion() || '';
            if (webVersion !== cookieVersion) { // Check for version change only if there is previous version
                setWebVersion(webVersion);
                RouteService.reload(Router, Router.currentRoute.path, Router.currentRoute.query, true);
            } else {
                if (!cookieVersion) { setWebVersion(webVersion); }
                return returnData(response);
            }
        } else {
            return returnData(response);
        }
    } else {
        return returnData(response);
    }
};

// Response interceptors
service.interceptors.response.use(
    (response) => {
        NProgress.done();
        return new Promise((resolve, reject) => {
            if (response && response.status !== 200) {
                handleErrorResponse(response).then(() => {
                    resolve(checkReload(response));
                }, (err) => {
                    reject(err);
                });
            } else {
                resolve(checkReload(response));
            }
        });
    },
    (error) => {
        NProgress.done();
        return new Promise((resolve, reject) => {
            if (error && error.response) {
                handleErrorResponse(error.response).then(() => {
                    reject(error.response);
                }, (err) => {
                    reject(err);
                });
            } else {
                reject(error);
            }
        });
    },
);

export default service;

