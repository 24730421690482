import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './lang';
import 'flex.box';
import 'animate.css';

Vue.config.productionTip = false;

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|  THIRD PARTS COMPONENTS |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ */
import 'vue-svgicon/dist/polyfill';
import SvgIcon from 'vue-svgicon';
import { setAccount } from '@/services/common/cookies.service';
import ViewService from '@/services/common/view.service';

// Vue.use(VueMeta);

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
});

(async () => {

  const getQueryStringValue = (key: string) => {
    return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
  };

  // Initialize the user and routes from server
  if (getQueryStringValue('account')) {
    setAccount(String(getQueryStringValue('account')));
  }

  window.onpopstate = async () => {
      await ViewService.refreshSystem(window.location.pathname, false);
  };
  await ViewService.refreshSystem(window.location.pathname, true); // First time loading

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');

})();
